<template>
  <div id="lottoset-setting" class="container">
    <div class="page-header mb-1">
      <strong>ตั้งค่าหวยชุด</strong>
    </div>

    <div v-if="roundId" class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>
      </div>
      <div class="card-body">
        <LottosetCloseTime :data="data" class="mb-4" @reload="loadRoundData" />
        <LottosetPrizeTable :data="data" @reload="loadRoundData" />
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"

import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'

import LottosetCloseTime from './components/LottosetCloseTime'
import LottosetPrizeTable from './components/LottosetPrizeTable'

export default {
  name: 'LottosetSetting',
  components: {
    LottosetCloseTime,
    LottosetPrizeTable
  },
  data() {
    return {
      data: null,
      isProcess: false
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    }
  },
  watch: {
    roundId() {
      this.loadRoundData()
    }
  },
  methods: {
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundSetting(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          if(response?.data?.market?.marketType === 'single') {
            this.$router.push({name: 'RoundSetting'})
          }
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.loadRoundData()
  }
}
</script>
