var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"close-time-modal",attrs:{"id":"close-time-modal","hide-footer":"","size":"md","title":"ตั้งเวลาหวย"},on:{"show":_vm.resetModal,"hidden":_vm.resetModal}},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"เปิดรับแทง"}},[_c('flat-pickr',{staticClass:"form-control text-success",attrs:{"config":{
          enableTime: true,
          enableSeconds: false,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i:S',
          maxDate: _vm.inputCloseTime
        }},model:{value:(_vm.inputOpenTime),callback:function ($$v) {_vm.inputOpenTime=$$v},expression:"inputOpenTime"}})],1),_c('b-form-group',{attrs:{"label":"ปิดรับแทง"}},[_c('flat-pickr',{staticClass:"form-control text-danger",attrs:{"config":{
          enableTime: true,
          enableSeconds: false,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i:S',
          minDate: _vm.inputOpenTime
        }},model:{value:(_vm.inputCloseTime),callback:function ($$v) {_vm.inputCloseTime=$$v},expression:"inputCloseTime"}})],1),_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.setGlobal),callback:function ($$v) {_vm.setGlobal=$$v},expression:"setGlobal"}},[_vm._v(" ใช้กับหวยงวดต่อไป ")]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"text-secondary mr-3",attrs:{"variant":"link","size":"sm"},on:{"click":_vm.hideModal}},[_vm._v("ยกเลิก")]),_c('b-button',{attrs:{"type":"submit","variant":"primary","size":"sm","disabled":_vm.isProcess}},[_vm._v(_vm._s(_vm.isProcess ? 'กำลังบันทึก...' : 'บันทึก'))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }