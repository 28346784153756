<template>
  <div>
    <div class="row mb-2">
      <div class="col-3">
        <h6>เวลาเปิดรับแทง</h6>
      </div>
      <div class="col-9">
        <h6 class="text-success">{{ openDate }}</h6>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <h6>เวลาปิดรับแทง</h6>
      </div>
      <div class="col-9">
        <h6 class="text-danger">{{ closeDate }}</h6>
      </div>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-9">
        <button class="btn btn-sm btn-outline-warning py-0" @click="editCloseTime">ตั้งเวลา เปิด/ปิด</button>
      </div>
    </div>

    <LottosetCloseTimeModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import LottosetCloseTimeModal from './LottosetCloseTimeModal'

export default {
  name: 'LottosetCloseTime',
  components: {
    LottosetCloseTimeModal
  },
  props: ['data'],
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    closeDate() {
      if(!this.data)
        return ''

      return moment(this.data?.round?.roundDate.close).format("DD/MM/YYYY HH:mm")
    },
    openDate() {
      if(!this.data)
        return ''

      return moment(this.data?.round?.roundDate.open).format("DD/MM/YYYY HH:mm")
    }
  },
  methods: {
    editCloseTime() {
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.time-box {
  width: 500px;
  max-width: 100%;

  h6 {
    margin-bottom: 0
  }
}
</style>
