<template>
  <div>
    <div class="row">
      <div class="col-3">
        <h6>ราคา</h6>
      </div>
      <div class="col-9">
        <h6><span class="text-primary">{{ price | amountFormat(2, '0.00') }}</span> บาท/ชุด</h6>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-3">
        <h6>ค่าคอมฯ</h6>
      </div>
      <div class="col-9">
        <h6><span class="text-primary">{{ discount | amountFormat(2, '0.00') }}</span> บาท/ชุด</h6>
      </div>
    </div> -->
    <div class="row">
      <div class="col-3">
        <h6>จำกัด</h6>
      </div>
      <div class="col-9">
        <h6><span class="text-primary">{{ limit ? limit : 'ไม่จำกัด' }}</span> ชุด/เลข</h6>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <h6>ราคาจ่าย</h6>
      </div>
      <div class="col-9">
        <table class="table table-sm table-bordered table-info">
          <thead>
            <tr>
              <th v-for="bet in prizes" :key="bet.code" class="text-center">{{ bet.text }}</th>
            </tr>
          </thead>
          <tbody class="bg-light text-primary">
            <tr>
              <td v-for="bet in prizes" :key="bet.code" class="text-right">{{ bet.prize | amountFormat(2, '0.00') }}</td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-sm btn-outline-warning py-0" @click="toggleSetting">ตั้งค่าราคา</button>
      </div>
    </div>

    <LottosetPrizeModal :is-show="isShowModal" :data="editData" @close="modalClosed" />
  </div>
</template>
<script>
import { marketTypes } from '@/configs/market.config'
import LottosetPrizeModal from './LottosetPrizeModal'

export default {
  name: 'LottosetPrizeTable',
  props: ['data'],
  components: {
    LottosetPrizeModal
  },
  data() {
    return {
      isShowModal: false,
      editData: null
    }
  },
  computed: {
    price() {
      return this.data?.round?.lottoSet?.price ?? (this.data?.market?.lottoSet?.price ?? 0)
    },
    discount() {
      return this.data?.round?.lottoSet?.discount ?? (this.data?.market?.lottoSet?.discount ?? 0)
    },
    prizes() {
      const marketType = marketTypes.find((t)=>{
        return t.code === this.data?.market?.marketType
      })

      const set = (marketType?.sets || []).find((s)=>{
        return s.set === this.data?.market?.marketSet
      })

      const prizes = {
        ...this.data?.market?.lottoSet?.prizes,
        ...this.data?.round?.lottoSet?.prizes
      }

      return (set?.openBets || [])
      .filter((bet)=>{
        return this.data?.market?.openBets?.[bet.code]
      })
      .map((bet)=>{
        return {
          ...bet,
          prize: prizes?.[bet.code] || 0
        }
      })
    },
    limit() {
      return this.data?.round?.lottoSet?.limits?.fourNumber ?? (this.data?.market?.lottoSet?.limits.fourNumber ?? 0)
    }
  },
  methods: {
    toggleSetting() {
      this.isShowModal = true
      this.editData = {
        setGlobal: false,
        roundId: this.data.round._id,
        price: this.price,
        discount: this.discount,
        limit: this.limit,
        prizes: this.prizes
      }
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    }
  }
}
</script>
