<template>
  <b-modal
    ref="lottoset-prizes-modal"
    hide-footer
    title="ตั้งค่าหวยชุด"
    ok-variant="success"
    @show="setData"
    @hidden="hideModal"
  >
    <form
      id="market-set-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ราคา (บาท/ชุด)"
      >
        <b-form-input
          type="text"
          v-model="input.price"
          placeholder="ราคาขาย บาท/ชุด"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group
        label="ค่าคอมฯ (บาท/ชุด)"
      >
        <b-form-input
          type="text"
          v-model="input.discount"
          placeholder="ค่าคอมฯ บาท/ชุด"
          :number="true"
          required
        ></b-form-input>
      </b-form-group> -->

      <b-form-group
        label="จำกัด (ชุด)"
        description="0=ไม่จำกัด"
      >
        <b-form-input
          type="text"
          v-model="input.limit"
          placeholder="จำกัดต่อเลข"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <fieldset class="form-group">
        <legend class="bv-no-focus-ring col-form-label pt-0">ราคาจ่าย</legend>
        <table class="table table-sm table-bordered table-info mb-0">
          <thead>
            <tr>
              <th width="50%" class="text-center">ประเภท</th>
              <th width="50%" class="text-center">จ่าย (บาท)</th>
            </tr>
          </thead>
          <tbody class="bg-light text-dark">
            <tr v-for="prize in input.prizes" :key="prize.code">
              <td class="text-center">{{prize.text}}</td>
              <td>
                <b-form-input
                  type="text"
                  v-model="prize.prize"
                  size="sm"
                  :number="true"
                  required
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <b-form-checkbox
        v-model="input.setGlobal"
        :value="true"
        :unchecked-value="false"
      >
        ใช้กับหวยงวดต่อไป
      </b-form-checkbox>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">บันทึก</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import RoundService from "@/services/RoundService"
import cAlert from '@/helpers/alert'

export default {
  name: 'LottosetPrizeModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.data))
    },
    showModal() {
      this.$refs['lottoset-prizes-modal'].show()
    },
    hideModal() {
      this.$refs['lottoset-prizes-modal'].hide()
    },
    handleSubmit() {
      const prizes = (this.input.prizes || []).reduce((prizes, input)=>{
        prizes[input.code] = input.prize
        return prizes
      }, {})

      const setData = {
        setGlobal: this.input.setGlobal,
        isAvailable: true,
        price: this.input.price,
        discount: this.input.discount,
        prizes: prizes,
        limit: this.input.limit
      }

      this.isProcess = true
      RoundService.updateLottosetSetting(this.data.roundId, setData)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
